<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">Password Reset</h2>
        </b-link>
        <b-card-text class="mb-2">
          We will send you an email to reset your password
        </b-card-text>
        <validation-observer ref="simpleRules">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group label="Email" label-for="login-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-container class="d-flex justify-content-center my-1">
              <div
                v-if="isLoading"
                class="spinner-border text-primary"
                role="status"
              />
            </b-container>
            <b-button
              type="submit"
              variant="primary"
              :disabled="isLoading"
              block
              @click="submitForgotPassword"
            >
              Submit
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  methods: {
    submitForgotPassword() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.isLoading = true;

          const payload = new FormData();
          payload.append("email", this.userEmail);
          payload.append("admin", 1);

          this.$httpAuth.post(`verify_email`, payload).then((response) => {
            if (response.data.status) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  text: response.data.message,
                },
              });
            }
            this.isLoading = false;
          });
        }
      });
    },
  },

  data() {
    return {
      isLoading: false,
      userEmail: "",
      required,
      email,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
